import React, { useEffect } from "react";
import SEO from "../components/SEO";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../styles/Page.module.scss";
import { security } from "../util/security";
import { wealth } from "../util/wealth";
import { homeImage } from "../util/images";
import { chart } from "../util/images";
import { smartSave } from "../util/images";
import { apple } from "../util/images";
import { arrow } from "../util/images";
import { googlePlayStore } from "../util/images";
import { securityImage } from "../util/images";
import { hyreIndex } from "../util/images";
import DownloadButton from "../components/DownloadButton";
import { padlock } from "../util/images";
import DownloadSection from "../components/DownloadSection/DownloadSection";

const Home = () => {
  const navigate = useNavigate();

  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      const elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const handleSave = () => {
    navigate("/save");
  };

  const handleInvest = () => {
    navigate("/invest");
  };

  const handleHyre = () => {
    navigate("/hyre");
  };

  return (
    <>
      <SEO
        title="Ladda Home Page"
        description="Ladda Home Page"
        name="Ladda ng"
        type="article"
      />
      <div className={styles.container}>
        <div className={styles.headerBackground}>
          <div className={styles.containerBody}>
            <div className={styles.parentDiv1}>
              <div className={styles.leftDiv}>
                <h1>Invest for the future on your terms</h1>
                <div className={styles.mt4}>
                  <p className={styles.savepwidth}>
                    Ladda is your smart financial buddy that helps you save and
                    invest towards achieving your financial goals stress-free.
                  </p>
                </div>
                <p className={styles.mtIndex}>
                  Tell us your goals, and we’ll make them happen.
                </p>
                <div className={styles.mt16}>
                  <div className={styles.flex}>
                    <DownloadButton
                      href="https://play.google.com/store/apps/details?id=com.ladda.ladda"
                      imgSrc={googlePlayStore}
                      altText="Google Play Store"
                      title="Ladda on Google Playstore"
                      buttonText="Get it on"
                      storeName="Google Play"
                    />
                    <DownloadButton
                      href="https://apps.apple.com/ng/app/ladda/id1531879570"
                      imgSrc={apple}
                      altText="Apple Store"
                      title="Ladda on Apple Playstore"
                      buttonText="Get it on"
                      storeName="App Store"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.rightDiv}>
                <div className={styles.imageDiv4}>
                  <img
                    src={homeImage}
                    alt="Stress Free Investment"
                    className={styles.image}
                    width="auto"
                    height="auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.containerBody}>
          <div className={styles.parentDiv2}>
            <div className={styles.leftDiv}>
              <h2>We are certified and reliable</h2>
              <div className={styles.mt4}>
                <p className={styles.textJustify}>
                  Our saving and investing products are secure to invest in. We
                  provide the products in partnership with TrustBanc Asset
                  Management Limited which is regulated by the Securities and
                  Exchange Commission (SEC) in Nigeria. Our Robo-advisory
                  product is provided in partnership with Drive Wealth, which is
                  regulated by FINRA in the US.  
                </p>
              </div>
            </div>
            <div className={styles.rightDiv7}>
              <div className={styles.imageDiv6}>
                <img
                  src={securityImage}
                  alt="Security"
                  className={styles.image}
                  width="auto"
                  height="auto"
                />
              </div>
            </div>
 </div> 

          <div className={styles.m40}>
            <div className={styles.parentDivHome}>
              <div className={styles.leftDiv}>
                <img
                  src={chart}
                  alt="Chart"
                  className={styles.img}
                  width="auto"
                  height="auto"
                />
              </div>

              <div className={styles.rightDiv}>
                <div className={styles.widthR}>
                  <h2>Stress-free investing tailored to your needs</h2>
                </div>
                <div className={styles.mt1}>
                  <p className={styles.iHeaderWidth2}>
                    Grow your money confidently by investing in pre-vetted,
                    automated long-term assets designed by experts and tailored
                    to suit your appetite. No investing knowledge needed
                  </p>
                </div>
                <p className={styles.greenLink} onClick={handleInvest}>
                  <span className={styles.green}>Learn more</span>
                  <span className={styles.linkArrow}>
                    <img
                      src={arrow}
                      alt="Learn More Arrow"
                      width="auto"
                      height="auto"
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className={styles.parentDiv2}>
            <div className={styles.leftDiv}>
              <div className={styles.iHeaderWidth}>
                <div className={styles.colouredDivHeader}>
                  <div className={styles.headerWidth}>
                    Save smart for the future
                  </div>
                </div>
                <p>
                  Got goals? Create multiple saving plans and set aside enough
                  money to make them happen while earning interest.
                </p>
                <p className={styles.greenLink} onClick={handleSave}>
                  <span className={styles.green}>Learn more</span>
                  <span className={styles.linkArrow}>
                    <img
                      src={arrow}
                      alt="Learn More Arrow"
                      width="auto"
                      height="auto"
                    />
                  </span>
                </p>
              </div>
            </div>
            <div className={styles.rightDiv7}>
              <img
                src={smartSave}
                alt="Smart Save"
                className={styles.img}
                width="auto"
                height="auto"
              />
            </div>
          </div>

          <div className={styles.parentDivHome}>
            <div className={styles.leftDiv}>
              <img
                src={hyreIndex}
                alt="Hyre"
                className={styles.img2}
                width="auto"
                height="auto"
              />
            </div>

            <div className={styles.rightDiv}>
              <div className={styles.widthR}>
                <h2>
                  Unlock your financial potential with High Yield Real Estate
                  (HYRE)
                </h2>
              </div>
              <div className={styles.mt1}>
                <p className={styles.iHeaderWidth2}>
                  Protect your purchasing power with High Yield Real Estate
                  (HYRE), a financial instrument that offers a potential yield
                  of up to 20% per annum to retail investors.
                </p>
              </div>
              <p className={styles.greenLink} onClick={handleHyre}>
                <span className={styles.green}>Learn more</span>
                <span className={styles.linkArrow}>
                  <img
                    src={arrow}
                    alt="Learn More Arrow"
                    width="auto"
                    height="auto"
                  />
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.brownBackground}>
          <div className={styles.containerBody}>
            <div className={styles.parentDiv12}>
              <div className={styles.center}>
                <h2>Security you can trust</h2>
                <div className={styles.mt1}>
                  <p className={styles.p25}>
                    Our services are protected using advanced encrypted standard
                    so that your details and the interaction between your
                    devices and our servers are safe and kept private at all
                    times.
                  </p>
                </div>
              </div>

              <div className={styles.parentPadding2}>
                <div className={styles.leftDiv8}>
                  <div className={styles.imageDiv}>
                    <img
                      src={padlock}
                      alt="Padlock"
                      className={styles.image}
                      width="auto"
                      height="auto"
                    />
                  </div>
                </div>
                <div className={styles.rightDiv8}>
                  <div className={styles.marginList}>
                    <ul className={styles.list}>
                      {security.map(({ id, header, main }) => (
                        <li key={id} className={styles.bullet}>
                          <div className={styles.justify}>
                            <h3>{header}</h3>
                            <div className={styles.mt2}>
                              <div className={styles.paragraph}>{main}</div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.containerBody}>
          <div className={styles.parentDiv3}>
            <div className={styles.paddingH}>
              <div className={styles.pt2}>
                <h2 className={styles.center}>Your Ladda to building wealth</h2>
              </div>
              <div className={styles.parentDiv4}>
                <div className={styles.flex3}>
                  {wealth.map(({ id, icon, header, main }) => (
                    <div key={id}>
                      <div className={styles.center}>
                        <div className={styles.p2}>
                          <div key={styles.mtMobile}>
                            <div>{icon}</div>
                            <h4>{header}</h4>
                            <div className={styles.mt1}>
                              <div className={styles.paragraph}>{main}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.parentDiv5}>
        <div className={styles.downloadMaxWidth}>
          <div id="download-section">
            <DownloadSection
              text1={<>Need smart investment recommendations?</>}
              text2=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
