import SEO from "../components/SEO";
import styles from "../styles/Page.module.scss";
import DownloadSection from "../components/DownloadSection/DownloadSection";

const PrivacyPolicy = () => {
  return (
    <>
      <SEO
        title="Ladda Privacy Policy"
        description="Ladda Privacy Policy"
        name="Ladda ng"
        type="article"
      />
      <div className={styles.container}>
        <div className={styles.containerBody}>
          <div className={styles.parentDiv10}>
            <div className={styles.center}>
              <h1 className={styles.white}>Privacy Policy</h1>
            </div>
          </div>

          <div className={styles.m90}>
            <div className={styles.parentDiv6}>
              <div className={styles.headerDiv}>Privacy Policy</div>
              <div className={styles.privacyText}>
                <div className={styles.mtb1lr0}>
                  Thank you for visiting https://getladda.com (the “Site“). The
                  Site is owned and operated by Monafric Technology Limited.
                  Accordingly, this is an agreement between Monafric Technology
                  Limited and its relevant affiliates and subsidiaries
                  (collectively referred to herein as “the Company”, “Ladda”,
                  “we”, “us”, or “our”) and You (together with the Company, the
                  “Parties” and each a “Party”). This Privacy policy (“Privacy
                  Policy”) together with our Terms of Service (which can be
                  found at https://getladda.com/terms (“Terms of Service”) sets
                  out the basis on which any Personal Information we collect
                  from you or that you provide to us, will be processed by us.
                  By “Personal Information”, we mean any information which,
                  either alone or in combination with other data, enables you to
                  be directly or indirectly identified, for example your name,
                  email address, username, contact details or any unique
                  identifier such as an IP address, device ID or other online
                  identifier.{" "}
                </div>

                <div>
                  Please read the following carefully to understand what data we
                  collect, how that data is used and the ways it can be shared
                  by us. If you do not wish for your Personal Information to be
                  used in the ways described within this Privacy Policy then you
                  should not access or use the Site or use the services,
                  functions, or features offered from time to time on the Site
                  (“Services”).
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className={styles.parentDiv6}>
              <div className={styles.m50b}>
                <div className={styles.subHeaderDiv}>
                  1. What information do we collect?
                </div>
                <div className={styles.privacyText}>
                  <div className={styles.mt1rem}>
                    <ol type="i">
                      <li className={styles.numeral}>
                        <b>Information we collect about you: </b>
                        <ul className={styles.disc}>
                          <li>
                            registering for a https://getladda.com account or
                            wallet;
                          </li>
                          <li>using the Support Centre on the Site; or</li>
                          <li>
                            corresponding with us by phone, e-mail or otherwise
                          </li>
                        </ul>
                        The Personal Information most often collected and
                        maintained in a customer file includes customer
                        identification, transaction history including records of
                        payments made.
                      </li>
                      <li className={styles.numeral}>
                        <b> Information we collect about you: </b>
                        Each time you use the Services, we may automatically
                        collect the following information, which may be
                        considered to be Personal Information when combined with
                        other information about you:
                        <ul className={styles.disc}>
                          <li>
                            we do not collect any special categories of Personal
                            Information about you (this includes details about
                            your race or ethnicity, religious or philosophical
                            beliefs, sex life, sexual orientation, political
                            opinions, trade union membership, information about
                            your health and genetic and biometric data).
                          </li>
                          <li>
                            information about your visit, including the dates
                            and times you use the Site length of visits to
                            certain pages, page interaction information (such as
                            scrolling, clicks, and mouse-overs), methods used to
                            browse away from the page, and any phone number used
                            to call the contact phone number provided on our
                            Site.
                          </li>
                        </ul>
                      </li>
                    </ol>
                    <div>
                      <b>Special categories of data. </b>
                      We do not collect any special categories of Personal
                      Information about you (this includes details about your
                      race or ethnicity, religious or philosophical beliefs, sex
                      life, sexual orientation, political opinions, trade union
                      membership, information about your health and genetic and
                      biometric data).
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.m50b}>
                <div className={styles.subHeaderDiv}>
                  2. Purposes for which we collect your Personal Information
                </div>
                <div className={styles.privacyText}>
                  <div className={styles.mt1rem}>
                    Purposes for which we collect your Personal Information:
                    <ul>
                      <li>
                        establish and maintain a responsible commercial
                        relationship with you;
                      </li>
                      <li>
                        understand your needs and your eligibility for products
                        and services;
                      </li>
                      <li>inform you about trading and financing features;</li>
                      <li>
                        develop, enhance, and market products and services, and
                        provide products and services to you;
                      </li>
                      <li>process billing and collection of any fees;</li>
                      <li>conduct surveys and get feedback from you;</li>
                      <li>deliver products and services to you;</li>
                      <li>
                        provide you with news and other matters of general
                        interest to you as our customer; and,{" "}
                      </li>
                      <li>meet our legal and regulatory requirements.</li>
                      <li>
                        It is important that the Personal Information we hold
                        about you is accurate and current. Please keep us
                        informed if your Personal Information changes during
                        your relationship with us.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className={styles.m50b}>
                <div className={styles.subHeaderDiv}>
                  3. Information we collect about you
                </div>
                <div className={styles.privacyText}>
                  <div className={styles.mt1rem}>
                    We use IP addresses to analyze trends, administer the Site,
                    track customer movements, and gather broad demographic
                    information for aggregate use. For systems administration
                    and detecting usage patterns and troubleshooting purposes,
                    our web servers also automatically log standard access
                    information including browser type, access times/open mail,
                    URL requested, and referral URL. This information is not
                    shared with third parties and is used only on a need-to-know
                    basis. We reserve the right to block access for any customer
                    accessing the Site via a proxy service intending to conceal
                    originating identity. This includes access via the Tor
                    anonymity network.
                  </div>
                </div>
              </div>

              <div className={styles.m50b}>
                <div className={styles.subHeaderDiv}>
                  4. Legal bases on which we process your Personal Information
                </div>
                <div className={styles.privacyText}>
                  <div className={styles.mt1rem}>
                    <ol type="i">
                      <li>
                        We will process your Personal Information on the
                        following grounds:
                        <ul className={styles.disc}>
                          <li>
                            where it is necessary for us to perform pursuant to
                            our contract with you or in your interests; and/or
                          </li>
                          <li>
                            where it is necessary for our legitimate interests
                            (or those of a third party) and we consider that
                            your interests and fundamental rights do not
                            override those interests. Legitimate interests mean
                            the interest of our organization to conduct and
                            manage our business to enable us to better serve you
                            and provide you with a secure experience on the
                            Site. We ensure that we balance any potential impact
                            on you and your rights before we process your
                            Personal Information for our legitimate interests.
                            We do not use your Personal Information for
                            activities where our interests are overridden by the
                            impact on you (unless we have your consent or are
                            otherwise required or permitted to by law). You can
                            obtain further information about how we assess our
                            legitimate interests against any potential impact on
                            you in respect of specific activities by contacting
                            us.
                          </li>
                        </ul>
                      </li>
                      <li className={styles.mt1rem}>
                        Generally we do not rely on consent as a legal basis for
                        processing your Personal Information other than in
                        relation to our use of cookies (please see our cookies
                        policy for more details which can be found at
                        https://getladda.com/terms) or when we send third party
                        direct marketing communications to you via email or text
                        message.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>

              <div className={styles.m50b}>
                <div className={styles.subHeaderDiv}>
                  5. Security and Confidentiality
                </div>
                <div className={styles.privacyText}>
                  <div className={styles.mt1rem}>
                    <ol type="i">
                      <li>
                        We are committed to protecting your privacy. Internally,
                        only people with a business need to know Personal
                        Information, or whose duties reasonably require access
                        to it, are granted access to customers’ Personal
                        Information. Such individuals will only process your
                        Personal Information on our instructions and are subject
                        to a duty of confidentiality.
                      </li>
                      <li className={styles.half}>
                        The Site’s systems and data are reviewed periodically to
                        ensure that you are getting a quality service and that
                        leading security features are in place. We have put in
                        place procedures to deal with any actual or suspected
                        data breach and will notify you and any applicable
                        regulator of a breach where we are legally required to
                        do so.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>

              <div className={styles.m50b}>
                <div className={styles.subHeaderDiv}>6. Disclosures</div>
                <div className={styles.privacyText}>
                  <div className={styles.mt1rem}>
                    <ol type="i">
                      <li>
                        You agree that we have the right to share your Personal
                        Information with:
                        <ul className={styles.disc}>
                          <li>
                            You agree that we have the right to share your
                            Personal Information with:
                          </li>
                          <li>
                            Our service providers, to the extent necessary to
                            supply the Services to you.
                          </li>
                          <li>
                            Selected third parties, including analytics and
                            search engine providers that assist us in the
                            improvement and optimization of the Services.
                          </li>
                          <li>
                            Authorities and law enforcement agencies worldwide
                            either when ordered to do so or on a voluntary basis
                            if this appears reasonable and necessary to us
                            (please refer to our Law Enforcement Requests
                            Policy).{" "}
                          </li>
                        </ul>
                      </li>
                      <li className={styles.half}>
                        We will also disclose your Personal Information to third
                        parties:
                        <ul className={styles.disc}>
                          <li>
                            If our assets or a substantial part thereof are
                            acquired by a third party, in which case Personal
                            Information held by us about our customers will be
                            one of the transferred assets
                          </li>
                          <li>
                            If we are under a duty to disclose or share your
                            Personal Information in order to comply with any
                            legal obligation, or in order to enforce or apply
                            our Terms of Service and other agreements; or to
                            protect the rights, property, or safety of us, our
                            clients, or others.
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>

              <div className={styles.m50b}>
                <div className={styles.subHeaderDiv}>7. Transfers</div>
                <div className={styles.privacyText}>
                  <div className={styles.mt1rem}>
                    <ol type="i">
                      <li>
                        The Service is controlled and operated by us from
                        Nigeria. If you are not a resident of Nigeria or you are
                        located outside Nigeria, you are not authorized to use
                        the Service. If you choose to use the Service or provide
                        information to us, please note that Your Personal
                        Information will be stored in Nigeria and may be
                        transferred worldwide.
                      </li>
                      <li className={styles.mt1rem}>
                        The Service is controlled and operated by us from
                        Nigeria. If you are not a resident of Nigeria or you are
                        located outside Nigeria, you are not authorized to use
                        the Service. If you choose to use the Service or provide
                        information to us, please note that Your Personal
                        Information will be stored in Nigeria and may be
                        transferred worldwide.
                      </li>
                      <li className={styles.mt1rem}>
                        Personal Information and other data may therefore be
                        exported outside of the jurisdiction in which you
                        reside. Your Personal Information may be processed and
                        stored in a foreign country or countries. Under those
                        circumstances, the governments, courts, law enforcement,
                        or regulatory agencies of that country or those
                        countries may be able to obtain access to your Personal
                        Information through foreign laws. You need to be aware
                        that the privacy standards of those countries may be
                        different from those of the jurisdiction in which you
                        reside.
                      </li>
                      <li>
                        Unfortunately, the transmission of information via the
                        Internet is not completely secure. While we do our
                        utmost to protect your Personal Information, we cannot
                        guarantee the security of your data transmitted to us
                        over email or through the Site; any transmission is at
                        your own risk. Once we have received your data, we will
                        use strict procedures and security features to try to
                        prevent unauthorized access.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>

              <div className={styles.m50b}>
                <div className={styles.subHeaderDiv}>8. Your Rights</div>
                <div className={styles.privacyText}>
                  <div className={styles.mt1rem}>
                    When you use the Services, we collect, store, use and
                    otherwise process your personal information as described in
                    this Privacy Policy. We rely on a number of legal bases to
                    process your information, including where:
                    <ul className={styles.disc}>
                      <li>
                        necessary for our legitimate interests in providing and
                        improving the Services including offering you content
                        and advertising that may be of interest to you;
                      </li>
                      <li>
                        necessary for our legitimate interest in keeping the
                        Services, Sites and Apps safe and secure;
                      </li>
                      <li>
                        personal Information and other data may therefore be
                        exported outside of the jurisdiction in which you
                        reside. Your Personal Information may be processed and
                        stored in a foreign country or countries. Under those
                        circumstances, the governments, courts, law enforcement,
                        or regulatory agencies of that country or those
                        countries may be able to obtain access to your Personal
                        Information through foreign laws. You need to be aware
                        that the privacy standards of those countries may be
                        different from those of the jurisdiction in which you
                        reside.
                      </li>
                      <li>
                        necessary for the legitimate interests of our service
                        providers and partners;
                      </li>
                      <li>
                        necessary to perform our contractual obligations in the
                        Terms of Use;
                      </li>
                      <li>
                        you have consented to the processing, which you can
                        revoke at any time (however a revocation does not affect
                        the lawfulness of processing of your personal data that
                        occurred prior to the date of revocation);
                      </li>
                      <li>
                        necessary to comply with a legal obligation such as a
                        law, regulation, search warrant, subpoena or court order
                        or to exercise or defend legal claims; and necessary to
                        protect your vital interests, or those of others.
                      </li>
                    </ul>
                  </div>
                  <div>
                    You can:
                    <ul className={styles.disc}>
                      <li>
                        access personal information we have about you (we will
                        try to provide information within 30 days of your
                        request). You will not have to pay a fee to access your
                        Personal Information or to exercise any of your other
                        rights. We may charge a reasonable fee if your request
                        is clearly unfounded, repetitive or excessive.
                        Alternatively, we may refuse to comply with your request
                        in these circumstances; have your personal information
                        corrected or deleted (in most cases you can correct
                        personal information you have submitted to us by going
                        back to the specific Service, logging-in and making the
                        desired change);
                      </li>
                      <li>
                        in certain circumstances, you can object to our
                        processing of your personal information and we will
                        discontinue such processing unless we have compelling
                        legitimate grounds to continue;
                      </li>
                      <li>
                        personal Information and other data may therefore be
                        exported outside of the jurisdiction in which you
                        reside. Your Personal Information may be processed and
                        stored in a foreign country or countries. Under those
                        circumstances, the governments, courts, law enforcement,
                        or regulatory agencies of that country or those
                        countries may be able to obtain access to your Personal
                        Information through foreign laws. You need to be aware
                        that the privacy standards of those countries may be
                        different from those of the jurisdiction in which you
                        reside.
                      </li>
                      <li>
                        withdraw consent previously provided (however a
                        withdrawal does not affect the lawfulness of processing
                        of your personal data that occurred prior to the date of
                        withdrawal); or
                      </li>
                      <li>
                        if you believe that we have not complied with applicable
                        data protection laws, you may lodge a complaint with
                        your local supervisory authority. If you wish to inquire
                        as to whether we maintain any of your personal
                        information and if so, whether you wish to exercise any
                        of those rights that are available to you with respect
                        to such personal information, you may contact us as
                        described on this Site. We will respond to your request
                        within a reasonable timeframe.
                      </li>
                    </ul>
                  </div>
                  <div className={styles.mt1rem}>
                    Where we process your personal information for direct
                    marketing purposes, you can opt-out through the unsubscribe
                    link in the email communications we send to you, by changing
                    your subscription preferences in your account settings or as
                    otherwise specified in this Privacy Policy.
                  </div>
                  <div className={styles.mt1rem}>
                    We will retain your personal information for as long as
                    necessary to provide the Services to you and fulfil the
                    purposes described in this Privacy Policy. This is also the
                    case for third parties with whom we share your information
                    to perform services on our behalf. When we no longer need to
                    use your personal information and there is no need for us to
                    keep it to comply with our legal or regulatory obligations,
                    we will either remove it from our systems or anonymize it.
                    If you have registered with us and you no longer want us to
                    use your registration information to provide the Services to
                    you, you may close your account.{" "}
                  </div>
                </div>
              </div>

              <div className={styles.m50b}>
                <div className={styles.subHeaderDiv}>
                  9. What we may need from you
                </div>
                <div className={styles.privacyText}>
                  <div className={styles.mt1rem}>
                    We may need to request specific information from you to help
                    us confirm your identity and ensure your right to access
                    your Personal Information (or to exercise any of your other
                    rights). This is a security measure to ensure that Personal
                    Information is not disclosed to any person who has no right
                    to receive it. We may also contact you to ask you for
                    further information in relation to your request to speed up
                    our response.
                  </div>
                </div>
              </div>

              <div className={styles.m50b}>
                <div className={styles.subHeaderDiv}>
                  10. Time limit to respond
                </div>
                <div className={styles.privacyText}>
                  <div className={styles.mt1rem}>
                    We try to respond to all legitimate requests within one
                    month. Occasionally it may take us longer than a month if
                    your request is particularly complex or you have made a
                    number of requests. In this case, we will notify you and
                    keep you updated. response.
                  </div>
                </div>
              </div>

              <div className={styles.m50b}>
                <div className={styles.subHeaderDiv}>
                  Other Sites and protecting your Personal information
                </div>
                <div className={styles.privacyText}>
                  <div className={styles.mt1rem}>
                    This Privacy Policy and the use of your Personal Information
                    only applies to the information you provide. You are
                    cautioned that if you disclose Personal Information or
                    personally sensitive data through use of the Internet, such
                    as through chat rooms, communities, bulletin boards, or
                    other public online forums, this information may be
                    collected and used by other persons or companies over which
                    we have no control. It is your responsibility to review the
                    privacy statements, policies, terms, and conditions of any
                    person or company to whom you choose to link or with whom
                    you choose to contract. We take all reasonable endeavours to
                    protect and safeguard Personal Information, but there are
                    protective measures you should take, as well. Do not share
                    your Personal Information with others unless you clearly
                    understand the purpose of their request for it and you know
                    with whom you are dealing. Do not keep sensitive Personal
                    Information in your e-mail inbox or on Webmail. If you are
                    asked to assign passwords to connect you to your Personal
                    Information, you should use a secure password and always use
                    two-factor authentication (2FA), where available. You should
                    change your password regularly.
                  </div>

                  <div className={styles.mt1rem}>
                    <b>Third-Party Links: </b>The Service contains third party
                    links to other websites or services. Please be aware that we
                    are not responsible for the content or privacy practices of
                    such other third- party websites or services. We encourage
                    our users to be aware when they leave the Service and to
                    read the privacy statements of any other site that collects
                    personal information. When you use the Service or its
                    content, certain third parties may use automatic information
                    collection technologies to collect information about you or
                    your device. These third parties may include:
                    <ul className={styles.disc}>
                      <li>Advertisers, ad networks and ad servers;</li>
                      <li>Analytics companies;</li>
                      <li>Your mobile device manufacturer;</li>
                      <li>Your mobile service provider.</li>
                    </ul>
                  </div>

                  <div className={styles.mt1rem}>
                    These third parties may use tracking technologies to collect
                    information about you when you use the Service. The
                    information they collect may be associated with your
                    personal information or they may collect information,
                    including personal information, about your online activities
                    over time and across different websites, apps and other
                    online services websites. They may use this information to
                    provide you with interest based (behavioral) advertising or
                    other targeted content. We do not control these third
                    parties' tracking technologies or how they may be used. If
                    you have any questions about an advertisement or other
                    targeted content, you should contact the responsible
                    provider directly.
                  </div>
                </div>
              </div>

              <div className={styles.m50b}>
                <div className={styles.subHeaderDiv}>
                  12. Changes to our privacy policy
                </div>
                <div className={styles.privacyText}>
                  <div className={styles.mt1rem}>
                    Although most changes are likely to be minor, we may change
                    our Privacy Policy from time to time, and in our sole
                    discretion. Any changes we make to our Privacy Policy in the
                    future will be posted on this page and, where appropriate,
                    notified to you by e-mail. Please check back frequently to
                    see any updates or changes to our Privacy Policy.
                  </div>
                </div>
              </div>

              <div className={styles.m50b}>
                <div className={styles.subHeaderDiv}>13. Contact</div>
                <div className={styles.privacyText}>
                  <div className={styles.mt1rem}>
                    Please contact us via our website’s Contact Us link/page if
                    you have any questions about this Privacy Policy or the
                    personal information we maintain about you. Should you have
                    any concerns about how we handle your Personal Information,
                    please contact us in the first instance. We will do our best
                    to resolve your concern. Alternatively, you may prefer to
                    submit a complaint directly to the national supervisory
                    authority, details of which can be found online.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.m90}>
            <div className={styles.parentDiv6}>
              <div className={styles.headerDiv}>Cookie Policy</div>
              <div className={styles.privacyText}>
                <div className={styles.mtb1lr0}>
                  Welcome to Ladda (“the Site”). This Cookies Policy should be
                  read in conjunction with our Privacy Policy
                  <ol type="1">
                    <li>
                      The Site may use “cookies”. A cookie is a small text file
                      containing a unique identification number that identifies
                      a customer’s browser each time that customer visits a
                      website. The use of cookies is industry standard practice
                      and many major browsers are initially set up to accept
                      them. You can reset your browser to either refuse to
                      accept all cookies or to notify you when you have received
                      a cookie. However, if you refuse to accept cookies, you
                      may not be able to use some of the features available on
                      the Site.
                    </li>
                    <li className={styles.mhalf}>
                      If you choose to disable or block our cookies on your
                      computer or other device you will need to do this through
                      your browser. Click on the ‘Help’ menu on your particular
                      browser to learn how to manage your cookie preferences.
                      Alternatively, you can visit 'www.aboutcookies.org' for
                      comprehensive information on how to manage cookies.
                    </li>
                    <li className={styles.mhalf}>
                      We set cookies (first party cookies) on web pages,
                      however, where we require additional information, we may
                      also allow other companies to host cookies on our Site
                      (third party cookies).
                    </li>

                    <b className={styles.mhalf}>What do we want cookies for</b>

                    <li className={styles.mhalf}>
                      The cookies on the Site may be from any of the following
                      categories:
                      <ol type="i">
                        <li>
                          Strictly Necessary cookies – these are used for
                          technical reasons and are necessary to enable the Site
                          to operate efficiently so that you can navigate the
                          Site with ease and use specific features. These
                          include, for example, cookies that help us to debug
                          any errors. If these cookies are blocked or disabled,
                          some of the Site may not operate effectively.
                        </li>
                        <li>
                          Functionality cookies – these are used to improve the
                          functionality of the Site and make it easier to use.
                          They help us to identify you as a repeat user of the
                          Site and help us remember your preferences (for
                          example, your choice of language or region).
                        </li>
                        <li>
                          Analytical – these record your visit to our Site, the
                          pages you have visited and the links you have
                          followed. We will use this information to make the
                          Site and the advertising displayed on it (if any) more
                          relevant to your interests. We may also share this
                          information with third parties for this purpose.
                        </li>
                      </ol>
                    </li>

                    <b className={styles.mhalf}>How do we share cookies </b>

                    <li className={styles.mhalf}>
                      We may share information that we collect through cookies,
                      in aggregated form, with carefully selected third parties
                      for the purposes set out above, unless you disable or
                      block cookies.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.m90}>
            <div className={styles.parentDiv6}>
              <div className={styles.headerDiv}>
                Anti-Spam and Mobile Opt-Out Options
              </div>
              <div className={styles.privacyText}>
                <div className={styles.mtb1lr0}>
                  We need your consent to communicate with you electronically to
                  establish and maintain a responsible commercial relationship
                  with you; to understand your needs and your eligibility for
                  products and services; to recommend particular products,
                  services, and opportunities to you; to inform you about
                  trading and financing features; to provide information to you
                  about developments and new products, including changes and
                  enhancements to the Site; to develop, enhance, and market
                  products and services, and provide products and services to
                  you; to process billing and collection of any fees; to conduct
                  surveys and get feedback from you; to deliver products and
                  services to you; to provide you with news and other matters of
                  general interest to you as a customer; and, to meet our legal
                  and regulatory requirements.
                </div>
                <div className={styles.mtb1lr0}>
                  International anti-spam legislation and best practices in the
                  industry require informed, opt-in consent to receiving
                  commercial electronic messages; a meaningful unsubscribe
                  option for recipients; and, proper identification of message
                  senders. You may also control interest-based advertising on
                  your mobile device by enabling the “Limit Ad Tracking” setting
                  in your iOS device’s settings or “Opt out of Ads
                  Personalization” in your Android device’s settings. This will
                  not prevent you from seeing advertisements, but will limit the
                  use of device advertising identifiers to personalize ads based
                  on your interests. If you have questions about how to reject
                  cookies on your specific device please check with the device
                  manufacturer.{" "}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.m90}>
            <div className={styles.parentDiv6}>
              <div className={styles.headerDiv}>Law Enforcement</div>
              <div className={styles.privacyText}>
                <div className={styles.mtb1lr0}>
                  From time to time, we receive information requests about our
                  customers from law enforcement agencies around the world. This
                  page is here to provide you and law enforcement with
                  information about how these requests are processed.
                  <div className={styles.mtb1lr0}>
                    When we are contacted, law enforcement is generally
                    interested in two types of data: information about
                    customers’ identities and information about their activities
                    using our Services. When information requests are received,
                    we require that it be accompanied by appropriate legal
                    process. This can vary from place to place. For example,
                    production orders, search warrants, and subpoenas, but also
                    requests for voluntary disclosure of data may all amount to
                    legal process. We review each order and request for
                    voluntary disclosure to determine that it has valid legal
                    basis and that any response is narrowly tailored to ensure
                    that only the data to which law enforcement is entitled is
                    provided.
                  </div>
                  <div className={styles.mtb1lr0}>
                    We also reserve the right to make disclosures to authorities
                    in order to protect ourself, any Affiliates/Partners, and
                    our customers.{" "}
                  </div>
                  <div className={styles.mtb1lr4}>
                    We welcome inquiries from law enforcement agencies about our
                    policies and procedures. Please contact us at
                    inforequests@ladda.com{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.parentDiv5}>
        <div className={styles.downloadMaxWidth}>
          <div id="download-div">
            <DownloadSection
              text1="Need smart investment
              recommendations?"
              text2=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
