export const mobileMenu = [
  {
    id: 1,
    menuName: "Invest",
    url: "/invest",
  },
  {
    id: 2,
    menuName: "Save",
    url: "/save",
  },
  {
    id: 3,
    menuName: "HYRE",
    url: "/hyre",
  },
  {
    id: 4,
    menuName: "Referral",
    url: "/referral",
  },
  {
    id: 5,
    menuName: "FHC",
    url: "https://fhc.getladda.com/",
  },
  // {
  //   id: 3,
  //   menuName: "Pay Bills",
  //   url: "/pay-bills",
  // },
  // {
  //   id: 4,
  //   menuName: "Investment Calculator",
  //   url: "/investment-calculator",
  // },
  // {
  //   id: 5,
  //   menuName: "Savings Calculator",
  //   url: "/savings-calculator",
  // },
  // {
  //   id: 6,
  //   menuName: "Education Calculator",
  //   url: "/education-calculator",
  // },
  // {
  //   id: 7,
  //   menuName: "Retirement Calculator",
  //   url: "/retirement-calculator",
  // },
];
