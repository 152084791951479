/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import Scroll from "react-scroll";
import SEO from "../components/SEO";
import { Grid } from "@mui/material";
import styles from "../styles/Page.module.scss";
import { apple } from "../util/images";
import { applePlayStoreOrange } from "../util/images";
import { googlePlayStore } from "../util/images";
import { googlePlayStoreOrange } from "../util/images";
import {
  home,
  cooperation,
  redCarpet,
  healthCare,
  sportCar,
  kids,
  travel,
  bag,
  more,
  goal,
  savings,
  multiplySavings,
  individualSavings,
  individualSavings2,
  groupSavings,
  emergencySavings,
  regularSavings,
  oneOffSavings,
  invite,
  create,
  topup,
} from "../util/images";
import DownloadSection from "../components/DownloadSection/DownloadSection";
import DownloadButton from "../components/DownloadButton";

const Save = () => {
  const ScrollLink = Scroll.Link;
  return (
    <>
      <SEO
        title="Ladda Savings Page"
        description="Ladda Savings Page"
        name="Ladda ng"
        type="article"
      />
      <div className={styles.container}>
        <div className={styles.containerBody}>
          <div className={styles.parentDivSave}>
            <div className={styles.leftDiv}>
              <h1 className={styles.mtsave}>
                Spend less. <br /> Save more.
              </h1>
              <p className={styles.mt4}>
                <div className={styles.savepwidth}>
                  Don’t just save towards your financial goals, earn better
                  interest on your savings too.
                </div>
              </p>
              <div className={styles.mt1rem}>
                <div className={styles.flex}>
                  <DownloadButton
                    href="https://play.google.com/store/apps/details?id=com.ladda.ladda"
                    imgSrc={googlePlayStore}
                    altText="Google Play Store"
                    title="Ladda on Google Playstore"
                    buttonText="Get it on"
                    storeName="Google Play"
                  />
                  <DownloadButton
                    href="https://apps.apple.com/ng/app/ladda/id1531879570"
                    imgSrc={apple}
                    altText="Apple Store"
                    title="Ladda on Apple Playstore"
                    buttonText="Get it on"
                    storeName="App Store"
                  />
                </div>
              </div>
            </div>
            <div className={styles.rightDiv}>
              <div className={styles.imageDiv}>
                <img
                  src={savings}
                  alt="Savings"
                  className={styles.image}
                  width="auto"
                  height="auto"
                />
              </div>
            </div>
          </div>

          <div className={styles.parentDiv}>
            <div className={styles.center}>
              <h2>
                Two different ways to
                <br /> grow your savings
              </h2>
              <div className={styles.flexContainer}>
                <div className={`${styles.childDiv} ${styles.childDivOne}`}>
                  <div className={styles.textPadding}>
                    <h3>Individual Savings</h3>
                    <p>
                    Focus on your personal financial goals
                    </p>
                  </div>
                  <img
                    src={individualSavings}
                    alt="Individual Savings"
                    className={styles.image}
                  />
                </div>
                <div className={`${styles.childDiv} ${styles.childDivTwo}`}>
                  <div className={styles.textPadding}>
                    <h3>Group Savings</h3>
                    <p>
                    Fun way to save with friends
                    </p>
                  </div>
                  <img
                    src={groupSavings}
                    alt="Group Savings"
                    className={styles.image}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.parentDivSave}>
            <div className={styles.center}>
              <h2 className={styles.mtbSave}>
                Saving is more fun with friends
              </h2>
              <p>Follow these simple steps to start your group savings</p>

              <div className={styles.flexContainer}>
                <div className={styles.stepsChildDiv}>
                  <img src={create} alt="Create" className={styles.image} />
                  <h4>Create a group savings</h4>
                  <div className={styles.mt1}>
                    <p>
                      Open your Ladda app to create your
                      <br /> savings group and receive your
                      <br /> unique link.
                    </p>
                  </div>
                </div>
                <div className={styles.stepsChildDiv}>
                  <img src={invite} alt="Invite" className={styles.image} />
                  <h4>Invite your friends</h4>
                  <div className={styles.mt1}>
                    <p>
                      Copy the link and share it with your friends to invite
                      them to join your group.
                    </p>
                  </div>
                </div>
                <div className={styles.stepsChildDiv}>
                  <img src={topup} alt="Top up" className={styles.image} />
                  <h4>Top up</h4>
                  <div className={styles.mt1}>
                    <p>
                      Contribute to the group savings and watch your savings
                      grow.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.parentDivHome}>
            <div className={styles.leftDiv2}>
              <div className={styles.imageDiv}>
                <img
                  src={multiplySavings}
                  alt="Multiply Savings"
                  width="auto"
                  height="auto"
                  className={styles.mtMobile}
                />
              </div>
            </div>
            <div className={styles.rightDiv2}>
              <div className={styles.iHeaderWidthSave}>
                <h2>
                  Create multiple saving plans to make your life goals happen!
                </h2>
              </div>
              <p className={styles.mt1}>
                Whether it’s saving for a new home, kids’ education or
                travelling the world; focus on what matters and save your way to
                make it a reality
              </p>
            </div>
          </div>
        </div>

        <div className={styles.greyBackground}>
          <div className={styles.containerBody}>
            <div className={styles.parentDivSave2}>
              <div className={styles.leftDivSave}>
                <h2>
                  Beat Naira depreciation,
                  <br /> Save in Dollars
                </h2>
                <div className={styles.iHeaderWidth2}>
                  <p className={styles.mt1}>
                    Shield your savings from inflation by choosing a stable
                    currency. Save in dollars and keep your money’s value intact
                    with individual savings
                  </p>
                </div>
                <ScrollLink
                  to="download-section"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <div className={styles.investButton}>Start saving today</div>
                </ScrollLink>
              </div>
              <div className={styles.rightDivSave}>
                <img
                  src={individualSavings2}
                  alt="Individual Savings"
                  width="auto"
                  height="auto"
                  className={styles.image}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.containerBody}>
          {/* <div className={styles.m40}>
            <div className={styles.parentDiv3}>
              <div className={styles.m40}>
                <div className={styles.parentDiv4}>
                  <Grid container spacing={2}>
                    <Grid item lg={3} md={3} xs={12}>
                      <div className={styles.center}>
                        <div className={styles.savingGridWidth}>
                          <img
                            src={home}
                            alt="Home"
                            width="auto"
                            height="auto"
                          />
                          <h4>House/Rent</h4>
                          <p className={styles.mt1}>
                            Save up to buy your dream house or to make rent
                            payment easier
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={3} md={3} xs={12}>
                      <div className={styles.center}>
                        <div className={styles.savingGridWidth}>
                          <img
                            src={travel}
                            alt="Vacation"
                            width="auto"
                            height="auto"
                          />
                          <h4>Vacations/Trips</h4>
                          <p className={styles.mt1}>
                            Set money aside take your dream vocations then and
                            again
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={3} md={3} xs={12}>
                      <div className={styles.center}>
                        <div className={styles.savingGridWidth}>
                          <img
                            src={healthCare}
                            alt="Health"
                            width="auto"
                            height="auto"
                          />
                          <h4>Health</h4>
                          <p className={styles.mt1}>
                            Keep a budget for personal and family health
                            emergencies.
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={3} md={3} xs={12}>
                      <div className={styles.center}>
                        <div className={styles.savingGridWidth}>
                          <img src={cooperation} alt="Cooperation" />
                          <h4>Cooperation</h4>
                          <p className={styles.mt1}>
                            Save to finance your business or save from your
                            business
                          </p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className={styles.parentDiv4}>
                  <Grid container spacing={2}>
                    <Grid item lg={1} md={1} xs={12}></Grid>
                    <Grid item lg={3} md={4} xs={12}>
                      <div className={styles.center}>
                        <div className={styles.savingGridWidth}>
                          <img
                            src={sportCar}
                            alt="Car"
                            width="auto"
                            height="auto"
                          />
                          <div className={styles.mt3}>
                            <h4>Car</h4>
                            <p className={styles.mt1}>
                              Want that sleek car? Save towards buying it
                            </p>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                      <div className={styles.center}>
                        <div className={styles.savingGridWidth}>
                          <img
                            src={redCarpet}
                            alt="Ceremony"
                            width="auto"
                            height="auto"
                          />
                          <h4>Ceremony</h4>
                          <p className={styles.mt1}>
                            Create a saving plan for weddings, housewarmings,
                            graduations and more
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={3} md={3} xs={12}>
                      <div className={styles.center}>
                        <div className={styles.savingGridWidth}>
                          <img
                            src={kids}
                            alt="Kids"
                            width="auto"
                            height="auto"
                          />
                          <h4>Kids</h4>
                          <p className={styles.mt1}>
                            Secure your kids’ future. Save to cover their
                            education
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg={1} md={0} xs={12}></Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div> */}

          <div className={styles.parentDiv}>
            <div className={styles.m90}>
              <div className={styles.flexContainer}>
                <div className={styles.childDivThree}>
                  <div>
                    <h3>Personalize your<br/> savings plan</h3>
                    <p>Save with any of these plans to earn interest</p>
                  </div>
                </div>
                <div className={`${styles.childDiv} ${styles.childDivFour}`}>
                  <div className={styles.textPadding2}>
                    <h3>Emergency Savings</h3>
                    <p>Save for the rainy day</p>
                  </div>
                  <img
                    src={emergencySavings}
                    alt="Emergency Savings"
                    className={styles.image}
                  />
                </div>
              </div>
              <div className={styles.mtbhyre}>
                <div className={styles.flexContainer}>
                  <div className={`${styles.childDiv} ${styles.childDivFive}`}>
                    <div className={styles.textPadding2}>
                      <h3>Regular Savings</h3>
                      <p>
                      Lock your funds away for a fixed period.
                      </p>
                    </div>
                    <img
                      src={regularSavings}
                      alt="Regular Savings"
                      className={styles.image}
                    />
                  </div>
                  <div className={`${styles.childDiv} ${styles.childDivSix}`}>
                    <div className={styles.textPadding2}>
                      <h3>One Off Savings</h3>
                      <p>
                      Save a specific amount at once.
                      </p>
                    </div>
                    <img
                      src={oneOffSavings}
                      alt="One Off Savings"
                      className={styles.image}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.mtb100}>
            <div className={styles.center}>
              <h2>Why save with Ladda?</h2>
            </div>
            <div className={styles.parentPadding}>
              <Grid container spacing={4}>
                <Grid item lg={4} md={4} xs={12}>
                  <div className={styles.saveBox1}>
                    <div>
                      <img
                        src={goal}
                        alt="Goal Oriented"
                        width="auto"
                        height="auto"
                      />
                    </div>
                    <div className={styles.savingGridWidth}>
                      <h4>Goal Oriented</h4>
                      <div className={styles.mt1}>
                        <p>Reach multiple goals by saving towards them</p>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <div className={styles.saveBox2}>
                    <div>
                      <img
                        src={bag}
                        alt="Flexible"
                        width="auto"
                        height="auto"
                      />
                    </div>
                    <div className={styles.savingGridWidth}>
                      <h4>Flexible</h4>
                      <div className={styles.mt1}>
                        <p>Manage your money any time, any day</p>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <div className={styles.saveBox3}>
                    <img
                      src={more}
                      alt="Earn more"
                      width="auto"
                      height="auto"
                    />
                    <div className={styles.savingGridWidth}>
                      <h4>Earn more</h4>
                      <div className={styles.mt1}>
                        <p>Get better interest than your bank when you save</p>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.parentDiv5}>
        <div className={styles.downloadMaxWidth}>
          <div id="download-section">
            <DownloadSection
              text1={
                <>
                  Now is the time <br />
                  to start saving.
                </>
              }
              text2="Get Started."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Save;
