/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Scroll from "react-scroll";
// import { Link, animateScroll as scroll } from "react-scroll";
import { AiOutlineClose } from "react-icons/ai";
import { HiMenuAlt3 } from "react-icons/hi";
import { logo } from "../../util/images";
import { menu } from "../../util/menu";
import { mobileMenu } from "../../util/mobileMenu";
import styles from "./Navbar.module.scss";

const Navbar = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 768);
  const [selectedMenu, setSelectedMenu] = useState<string | number>("");
  const [isInvestPage, setInvestPage] = useState<boolean>(false);

  const ScrollLink = Scroll.Link;
  const navigate = useNavigate();

  const currentURL = window.location.pathname;

  const openMenu = ({ url }: any) => {
    if (url == "https://fhc.getladda.com/") {
      window.open(url, "_blank");
    } else navigate(url);
  };

  const openMobilePage = ({ url }: any) => {
    if (url == "https://fhc.getladda.com/") {
      window.open(url, "_blank");
    } else navigate(url);
    setIsShown(false);
  };

  const openFHC = () => {
    const url = "https://fhc.getladda.com/";
    window.open(url, "_blank");
  };

  const handleMobileDownload = () => {
    setIsShown(false);
    <ScrollLink
      to="download-section"
      spy={true}
      smooth={true}
      offset={-100}
      duration={500}
    />;
  };

  const goHome = () => {
    navigate("/");
    setSelectedMenu(0);
  };

  const goHomeMobile = () => {
    navigate("/");
    setIsShown(false);
  };

  const updateMedia = () => {
    setDesktop(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const [isShown, setIsShown] = useState(false);

  const handleToggle = () => {
    setIsShown(!isShown);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setSelectedMenu(0);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSavings = () => {
    navigate("savings-calculator");
    setAnchorEl(null);
  };

  const handleInvestment = () => {
    navigate("investment-calculator");
    setAnchorEl(null);
  };

  const handleRetirement = () => {
    navigate("retirement-calculator");
    setAnchorEl(null);
  };

  const handleEducation = () => {
    navigate("education-calculator");
    setAnchorEl(null);
  };

  const menuMap: any = {
    "/invest": 1,
    "/save": 2,
    "/hyre": 3,
    "/referral": 4,
  };

  useEffect(() => {
    const selectedMenu = menuMap[currentURL];
    setInvestPage(false);
    setSelectedMenu(selectedMenu);
    if (currentURL === "/invest") {
      setInvestPage(true);
    }
  }, [currentURL]);

  const handleInvestDownload = () => {
    <Link to="/#download-section" />;
  };

  return (
    <>
      {isDesktop ? (
        <div className={styles.navbarContainer}>
          <div className={styles.navbar}>
            <div className={styles.navbarLogo} onClick={goHome}>
              <img src={logo} alt="Ladda Logo" width="auto" height="auto" />
            </div>

            <div className={styles.menu}>
              {menu.map(({ id, menuName, url }) => (
                <div
                  key={id}
                  className={
                    selectedMenu === id ? styles.activeMenu : styles.menuItem
                  }
                  onClick={() => {
                    openMenu({ url });
                    setSelectedMenu(id);
                  }}
                >
                  {selectedMenu === id ? (
                    <div className={styles.activeColorText}>{menuName}</div>
                  ) : (
                    <div>{menuName}</div>
                  )}
                </div>
              ))}

              <div>
                <>
                  <ScrollLink
                    to="download-section"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    <div className={styles.downloadButton}>Download app</div>
                  </ScrollLink>
                </>
              </div>
              <div className={styles.fhcButton} onClick={openFHC}>
                Financial Health Check
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.navbarContainerMobile}>
          <div className={styles.navBarMobile}>
            <div className={styles.logoMobile} onClick={goHomeMobile}>
              <img src={logo} alt="Ladda Logo" width="auto" height="auto" />
            </div>
            <div className={styles.mobileMenu}>
              <div onClick={handleToggle}>
                {!isShown ? (
                  <HiMenuAlt3 size={42} />
                ) : (
                  <AiOutlineClose size={42} />
                )}
              </div>

              {isShown && (
                <div className={styles.mobileMenuDiv}>
                  <div className={styles.mobileMenuList}>
                    {mobileMenu.map(({ id, menuName, url }) => (
                      <li
                        className={styles.mobileMenuItem}
                        key={id}
                        onClick={() => openMobilePage({ url })}
                      >
                        <div className={styles.mobileMenuItemName}>
                          {menuName}
                        </div>
                      </li>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
