import SEO from "../components/SEO";
import styles from "../styles/Page.module.scss";
import Accordion from "../components/Accordion/Accordion";
import DownloadSection from "../components/DownloadSection/DownloadSection"

const Faqs = () => {
  return (
    <>
      <SEO
        title="Ladda Faqs"
        description="Ladda Faqs"
        name="Ladda ng"
        type="article"
      />
      <div className={styles.container}>
        <div className={styles.parentDiv10}>
          <div className={styles.center}>
            <h1 className={styles.white}>
              <span className={styles.p5}>
                Frequently Asked Questions (FAQs)
              </span>
            </h1>
          </div>
        </div>
        
        <div className={styles.mt100}>
        <div className={styles.parentDiv6}>
          <div className={styles.p5}>
            <Accordion />
          </div>
        </div>
        </div>
        <div id="download-section">
        <DownloadSection
          text1="Make savings a priority today."
          text2=" Save Now."
        />
        </div>
      </div>
    </>
  );
};

export default Faqs;
