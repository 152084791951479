import SEO from "../components/SEO";
import styles from "../styles/Page.module.scss";
import DownloadSection from "../components/DownloadSection/DownloadSection";
import { CgMenuRound } from "react-icons/cg";

const AccountDeletion = () => {
  return (
    <>
      <SEO
        title="Ladda Account Deletion"
        description="Ladda Account Deletion"
        name="Ladda ng"
        type="article"
      />
      <div className={styles.container}>
        <div className={styles.parentDiv10}>
          <div className={styles.center}>
            <h1 className={styles.white}>Account Deletion</h1>
          </div>
        </div>

        <div>
          <div className={styles.parentDiv6}>
            <div className={styles.m50b}>
              <div className={styles.subHeaderDiv}>
                To delete your account, please follow these steps:
              </div>
              <div className={styles.m50b}>
                <div className={styles.privacyText}>
                  <div className={styles.mt1rem}>
                    <div>
                      <strong>Step 1: Log in to your account on the App</strong>
                    </div>
                    <div>
                      Log in to your account on the Ladda app using your
                      existing credentials. If you’re already logged in, proceed
                      to Step 2.
                    </div>
                  </div>

                  <div className={styles.mt1rem}>
                    <div>
                      <strong>Step 2: Go to the menu bar</strong>
                    </div>
                    <div>
                      On the top left of your device, tap on this icon <CgMenuRound/> to open the menu bar options.
                    </div>
                  </div>

                  <div className={styles.mt1rem}>
                    <div>
                      <strong>Step 3: Access the help and support page</strong>
                    </div>
                    <div>
                      On the menu bar options page, tap on ‘Help and Support’.
                    </div>
                  </div>

                  <div className={styles.mt1rem}>
                    <div>
                      <strong>
                        Step 4: Navigate to the Delete Account option
                      </strong>
                    </div>
                    <div>
                      On the help and Support Page, tap on the ‘Delete Account’
                      option located at the bottom of the screen. Tap on this
                      option to initiate the deletion process.
                    </div>
                  </div>

                  <div className={styles.mt1rem}>
                    <div>
                      <strong>Step 5: Check Your email</strong>
                    </div>
                    <div>
                      Keep an eye on your email for a confirmation message
                      regarding the account deletion. This email will typically
                      contain important information about the process and any
                      additional steps required. That’s it! Your account
                      deletion process is underway. If you have any questions or
                      encounter any issues, please contact our support team at{" "}
                      <span style={{ color: "#fb8b19" }}>
                        hello@getladda.com{" "}
                      </span>
                      for assistance.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountDeletion;
