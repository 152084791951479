// DownloadButton.tsx
import React from 'react';
import styles from "../../styles/Page.module.scss";

interface DownloadButtonProps {
  href: string;
  imgSrc: string;
  altText: string;
  title: string;
  buttonText: string;
  storeName: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
  href,
  imgSrc,
  altText,
  title,
  buttonText,
  storeName,
}) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      title={title}
    >
      <div className={styles.downloadButton}>
        <div className={styles.divflex}>
          <img
            src={imgSrc}
            alt={altText}
            width="auto"
            height="auto"
          />
          <div className={styles.buttonText}>
            <div>
              <span>{buttonText}</span>
              <br />
              <div className={styles.mt2}>{storeName}</div>
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default DownloadButton;
