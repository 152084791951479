/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { lazy, Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import ScrollToTop from "./components/ScrollToTop";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Home from "./views/Home";
import Invest from "./views/InvestNew";
import Save from "./views/Save";
import Hyre from "./views/Hyre";
import Referral from "./views/Referral";
import PayBills from "./views/PayBills";
import PrivacyPolicy from "./views/PrivacyPolicy";
import AccountDeletion from "./views/AccountDeletion";
import TermsAndConditions from "./views/TermsAndConditions";
import Legal from "./views/Legal";
import Faqs from "./views/Faqs";
import AboutUs from "./views/AboutUs";
import InvestmentCalculator from "./views/InvestmentCalculator";
import SavingsCalculator from "./views/SavingsCalculator";
import RetirementCalculator from "./views/RetirementCalculator";
import EducationCalculator from "./views/EducationCalculator";
import NotFound from "./views/NotFound";
// const Home = lazy(() => import("./views/Home"));
// const Invest = lazy(() => import("./views/Invest"));
// const Save = lazy(() => import("./views/Save"));

const App = () => {
  const helmetContext = {};
  return (
    <>
      <HelmetProvider context={helmetContext}>
        <Router>
          <div className="App-container">
            <Navbar />
            <ScrollToTop>
              <Routes>
                <Route index element={<Home />} />
                <Route path="invest" element={<Invest />} />
                <Route path="save" element={<Save />} />
                <Route path="hyre" element={<Hyre />} />
                <Route path="referral" element={<Referral />} />
                <Route path="privacy-policy" element={<PrivacyPolicy />} />
                <Route path="account-deletion" element={<AccountDeletion />} />
                <Route path="about-us" element={<AboutUs />} />
                <Route path="faqs" element={<Faqs />} />
                <Route path="legal" element={<Legal />} />
                <Route
                  path="terms-and-conditions"
                  element={<TermsAndConditions />}
                />
                {/* <Route
                  path="investment-calculator"
                  element={<InvestmentCalculator />}
                />
                <Route
                  path="savings-calculator"
                  element={<SavingsCalculator />}
                />
                <Route
                  path="education-calculator"
                  element={<EducationCalculator />}
                />
                <Route
                  path="retirement-calculator"
                  element={<RetirementCalculator />}
                /> */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </ScrollToTop>
            <Footer />
          </div>
        </Router>
      </HelmetProvider>
    </>
  );
};

export default App;
