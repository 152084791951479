export const menu = [
  {
    id: 1,
    menuName: "Invest",
    url: "/invest",
  },
  {
    id: 2,
    menuName: "Save",
    url: "/save",
  },
  {
    id: 3,
    menuName: "HYRE",
    url: "/hyre",
  },
  {
    id: 4,
    menuName: "Referral",
    url: "/referral",
  },
];
