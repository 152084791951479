import React, { useEffect, useState } from "react";
import styles from "./Accordion.module.scss";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { investmentFaq } from "../../util/investmentFaq";

const Accordion = () => {
  const [isActive, setIsActive] = useState(false);
  const [isCapitalActive, setCapitalActive] = useState(false);
  const [isBenefitActive, setBenefitActive] = useState(false);
  const [isInvestmentActive, setInvestmentActive] = useState(false);
  const [isStepsActive, setStepsActive] = useState(false);

  return (
    <>
      <div className={styles.accordion}>
        <>
          <div
            className={styles.accordionItem}
            onClick={() => setIsActive(!isActive)}
          >
            <div className={styles.accordionTitle}>
              <div className={styles.accordionHyreTitleName}>
                <div>How do I find my referral link?</div>
              </div>
              <div className={styles.icons}>
                {isActive ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </div>
            <div className={styles.accordionDividerHyre}></div>
            {isActive && (
              <div className={styles.accordionContent}>
                <div>
                  <p className={styles.mpHyre}>
                    Find your referral link in the app under settings in the
                    'Refer a Friend' section.
                  </p>
                </div>
              </div>
            )}
          </div>

          <div
            className={styles.accordionItem}
            onClick={() => setCapitalActive(!isCapitalActive)}
          >
            <div className={styles.accordionTitle}>
              <div className={styles.accordionHyreTitleName}>
                <div>What rewards can I earn?</div>
              </div>
              <div className={styles.icons}>
                {isCapitalActive ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </div>
            <div className={styles.accordionDividerHyre}></div>
            {isCapitalActive && (
              <div className={styles.accordionContent}>
                <div>
                  <p className={styles.mpHyre}>
                    For each successful referral, you’ll receive a percentage
                    off your next savings deposit, plus any other promotional
                    rewards we may offer!
                  </p>
                </div>
              </div>
            )}
          </div>

          <div
            className={styles.accordionItem}
            onClick={() => setBenefitActive(!isBenefitActive)}
          >
            <div className={styles.accordionTitle}>
              <div className={styles.accordionHyreTitleName}>
                <div>Are there limits to how many friends I can refer?</div>
              </div>
              <div className={styles.icons}>
                {isBenefitActive ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </div>
            <div className={styles.accordionDividerHyre}></div>
            {isBenefitActive && (
              <div className={styles.accordionContent}>
                <div>
                  <p className={styles.mpHyre}>
                    There’s no limit! Refer as many friends as you like and
                    watch your rewards grow!
                  </p>
                </div>
              </div>
            )}
          </div>
        </>
      </div>
    </>
  );
};
export default Accordion;
