/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import Scroll from "react-scroll";
import { Grid } from "@mui/material";
import SEO from "../components/SEO";
import styles from "../styles/Page.module.scss";
import { apple } from "../util/images";
import { googlePlayStore } from "../util/images";
import { investStressFree } from "../util/images";
import { phone } from "../util/images";
import { phone2 } from "../util/images";
import { steps } from "../util/images";
import { shareGoals } from "../util/images";
import { feeChart } from "../util/images";
import { fees } from "../util/fees";
import DownloadSection from "../components/DownloadSection/DownloadSection";
import DownloadButton from "../components/DownloadButton";

const Invest = () => {
  const ScrollLink = Scroll.Link;
  return (
    <>
      <SEO
        title="Ladda Investment Page"
        description="Ladda Investment Page"
        name="Ladda ng"
        type="article"
      />
      <div className={styles.container}>
        <div className={styles.containerBody}>
          <div className={styles.parentDivInvest}>
            <div className={styles.leftDiv3}>
              <div className={styles.textWidth}>
                <h1>Stress-free investing tailored to your needs</h1>
                <p className={styles.mt1}>
                  You don’t need to become a financial expert to invest in your
                  future. With our done-for-you investment vehicles, you simply
                  tell us your goals and sit back while your money grows.
                </p>

                <ScrollLink
                  to="fees"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  {" "}
                  <div className={styles.investButton}>See our Fees</div>
                </ScrollLink>
              </div>
            </div>

            <div className={styles.rightDivInvest}>
              <div className={styles.imageDiv}>
                <img
                  src={investStressFree}
                  alt="Stress Free Investment"
                  width="auto"
                  height="auto"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.greenBackground}>
          <div className={styles.containerBody}>
            <div className={styles.parentDivInvest2}>
              <div className={styles.leftDiv2}>
                <div className={styles.imageDiv3}>
                  <img
                    src={shareGoals}
                    alt="Share Goals"
                    className={styles.image}
                    width="auto"
                    height="auto"
                  />
                </div>
              </div>
              <div className={styles.rightDiv2}>
                <div className={styles.stepsHeaderFull}>
                  Share your goals with us and we will take care of the rest
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.containerBody}>
          <div className={styles.parentDivCenter}>
            <div className={styles.leftDivInvest}>
              <div className={styles.stepsHeader}>
                You can’t work forever but your money can. Automate your
                investment in 3 steps
              </div>

              <div className={styles.imageDiv2}>
                <img
                  src={steps}
                  alt="Steps"
                  className={styles.image}
                  width="auto"
                  height="auto"
                />
              </div>

              <div className={styles.pInvest}>
                <div className={styles.flex}>
                  <DownloadButton
                    href="https://play.google.com/store/apps/details?id=com.ladda.ladda"
                    imgSrc={googlePlayStore}
                    altText="Google Play Store"
                    title="Ladda on Google Playstore"
                    buttonText="Get it on"
                    storeName="Google Play"
                  />
                  <DownloadButton
                    href="https://apps.apple.com/ng/app/ladda/id1531879570"
                    imgSrc={apple}
                    altText="Apple Store"
                    title="Ladda on Apple Playstore"
                    buttonText="Get it on"
                    storeName="App Store"
                  />
                </div>
              </div>
            </div>

            <div className={styles.imageDivInvest}>
              <img src={phone} alt="Phone" />
            </div>
          </div>
          <div className={styles.parentDiv13}>
            <div className={styles.leftDiv}>
              <div className={styles.imageDiv3}>
                <img
                  src={phone2}
                  alt="Phone2"
                  className={styles.image}
                  width="auto"
                  height="auto"
                />
              </div>
            </div>
            <div className={styles.rightDiv}>
              <div className={styles.ml1}>
                <h2>
                  Grow wealth, even while you sleep. Your portfolio will be kept
                  in check automatically
                </h2>
                <p className={styles.mt1}>
                  Your portfolio will automatically adjust to best align with
                  your objectives as you invest more money or as market
                  conditions change.
                </p>
                <ScrollLink
                  to="download-div"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <div className={styles.investButton}>Invest Now</div>
                </ScrollLink>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.greyBackground}>
          <div className={styles.containerBody}>
            <div className={styles.parentDiv15} id="fees">
              <div className={styles.parentDiv}>
                <div className={styles.center}>
                  <h2>Our Fees </h2>
                  <div className={styles.mt1}>
                    <p className={styles.p20}>
                      Unlike platforms that allow you to trade (buy and sell)
                      and charge you a fee on each trade (typically 1.5%), our
                      robo-advisory fees range from 0.85% - 2.0% annually,
                      depending on the size of your portfolio. We also charge a
                      one-off set-up fee of $2 to meet obligations for managing
                      your account with our US partners. 
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.mtb2rem}>
                <Grid container spacing={2}>
                  {fees.map(
                    ({
                      id,
                      title,
                      fee,
                      feeBackground,
                      annualFee,
                      advantage,
                      textColor,
                      backgroundColor,
                    }) => (
                      <Grid item lg={3} md={6} xs={12} key={id}>
                        <div
                          className={styles.feesBox}
                          style={{ backgroundColor: backgroundColor }}
                        >
                          <div
                            className={styles.header}
                            style={{ color: textColor }}
                          >
                            {title}
                          </div>
                          <div className={styles.feeHeight}>
                            <div
                              className={styles.fee}
                              style={{ backgroundColor: feeBackground }}
                            >
                              {fee}
                              <br />
                              <span>in assets</span>
                            </div>
                          </div>

                          <div className={styles.mt}>
                            <div className={styles.annualFee}>
                              {annualFee}
                              <br />
                              <span>Annual fees</span>
                            </div>
                          </div>

                          <div className={styles.annualFee}>
                            {advantage}
                            <br />
                            <span>Support</span>
                          </div>
                        </div>
                      </Grid>
                    )
                  )}
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.brownBackground}>
          <div className={styles.containerBody}>
            <div className={styles.parentDiv11}>
              <div className={styles.paddingHB}>
                <div className={styles.p15}>
                  <div className={styles.center}>
                    <h2>We offer more affordable prices compared to others</h2>
                    <div className={styles.feeSheet}>
                      <a
                        href="https://bit.ly/feemodelforladdaroboadvisory"
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Comparison Datasheet
                      </a>
                    </div>
                    <div className={styles.mtb2rem}>
                      <div className={styles.imageDiv}>
                        <img
                          src={feeChart}
                          alt="Chart"
                          width="auto"
                          height="auto"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.parentDiv5}>
        <div className={styles.downloadMaxWidth}>
          <div id="download-div">
            <DownloadSection
              text1="Need smart investment
              recommendations?"
              text2=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Invest;
