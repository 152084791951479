import React from "react";
import SEO from "../components/SEO";
import Accordion from "../components/Accordion/AccordionReferral";
import styles from "../styles/Page.module.scss";
import Scroll from "react-scroll";
import { apple } from "../util/images";
import { googlePlayStore } from "../util/images";
import referral from "../assets/referral.svg"
import { referral2, invite, signUp, rewards } from "../util/images";
import DownloadSection from "../components/DownloadSection/DownloadSection";
import DownloadButton from "../components/DownloadButton";

const Referral = () => {
  const ScrollLink = Scroll.Link;
  return (
    <>
      <SEO
        title="Ladda Referral Page"
        description="Ladda Referral Page"
        name="Ladda ng"
        type="article"
      />
      <div className={styles.container}>
        <div className={styles.containerBody}>
          <div className={styles.mt16}>
            <div className={styles.parentDivCenter}>
              <div className={styles.leftDiv}>
                <div className={styles.textWidth2}>
                  <h1>Unlock rewards with every referral</h1>
                  <p className={styles.mt4}>
                    Invite your friends to save and unlock amazing rewards
                    together.
                  </p>

                  <div className={styles.mt3rem}>
                    <div className={styles.flex}>
                      <DownloadButton
                        href="https://play.google.com/store/apps/details?id=com.ladda.ladda"
                        imgSrc={googlePlayStore}
                        altText="Google Play Store"
                        title="Ladda on Google Playstore"
                        buttonText="Get it on"
                        storeName="Google Play"
                      />
                      <DownloadButton
                        href="https://apps.apple.com/ng/app/ladda/id1531879570"
                        imgSrc={apple}
                        altText="Apple Store"
                        title="Ladda on Apple Playstore"
                        buttonText="Get it on"
                        storeName="App Store"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.rightDiv}>
                <div className={styles.imageDiv}>
                  <img
                    src={referral}
                    alt="Referral"
                    width="auto"
                    height="auto"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.parentDiv16}>
            <div className={styles.center}>
              <h2>Here’s how it works</h2>

              <div className={styles.flexContainer}>
                <div className={styles.stepsChildDiv}>
                  <img src={invite} alt="Invite" className={styles.image} />
                  <h4>Invite your Friends</h4>
                  <div className={styles.mt1}>
                    <p>
                      Share your unique referral link with friends and family.
                      The more, the merrier!
                    </p>
                  </div>
                </div>
                <div className={styles.stepsChildDiv}>
                  <img src={signUp} alt="Sign Up" className={styles.image} />
                  <h4>They sign up</h4>
                  <div className={styles.mt1}>
                    <p>
                      When your friends sign up using your link, they start
                      their own savings journey with Ladda.
                    </p>
                  </div>
                </div>
                <div className={styles.stepsChildDiv}>
                  <img src={rewards} alt="Top up" className={styles.image} />
                  <h4>Earn rewards</h4>
                  <div className={styles.mt1}>
                    <p>
                      For each successful referral, you’ll receive 10% off your
                      next savings deposit. It’s that simple!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.greyBackgroundReferral}>
          <div className={styles.containerBody}>
            <div className={styles.parentDivCenter}>
              <div className={styles.leftDiv}>
                <h2>
                  Earn together
                  <br /> Save together
                </h2>
                <div className={styles.iHeaderWidth}>
                  <p className={styles.mt1}>
                    At Ladda, we believe that savings should be rewarding, both
                    for you and your friends. Join us in making savings a fun
                    and profitable experience.
                  </p>
                </div>
                <ScrollLink
                  to="download-section"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <div className={styles.investButton}>
                    Start referring today
                  </div>
                </ScrollLink>
              </div>

              <div className={styles.rightDiv}>
                <div className={styles.imageDiv}>
                  <img
                    src={referral2}
                    alt="Referral"
                    width="auto"
                    height="auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.containerBody}>
          <div className={styles.paddingFaq}>
            <div className={styles.parentDiv16}>
              <div className={styles.leftDiv}>
                <div className={styles.textWidth2}>
                  <h1>FAQs</h1>
                </div>
              </div>

              <div className={styles.rightDiv}>
                <Accordion />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.parentDiv5}>
        <div className={styles.downloadMaxWidth}>
          <div id="download-section">
            <DownloadSection
              text1={
                <>
                  Now is the time <br />
                  to start saving.
                </>
              }
              text2="Invest now."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Referral;
