import SEO from "../components/SEO";
import styles from "../styles/Page.module.scss";
import DownloadSection from "../components/DownloadSection/DownloadSection";

const Legal = () => {
  return (
    <>
      <SEO
        title="Ladda Legal"
        description="Ladda Legal"
        name="Ladda ng"
        type="article"
      />
      <div className={styles.container}>
        <div className={styles.parentDiv10}>
          <div className={styles.center}>
            <h1 className={styles.white}>Legal</h1>
          </div>
        </div>

   
          {/* <DownloadSection /> */}
    
      </div>
    </>
  );
};

export default Legal;
