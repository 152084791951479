export const fees = [
  {
    id: 1,
    title: "Starters",
    fee: "$0 - $4,999",
    feeBackground: "#FFE8D1",
    annualFee: "2.00%",
    advantage: "Reliable and timely support",
    textColor: "var(--orange-1, #FB8B19);",
    backgroundColor: "var(--orange-5, #FFF3E7)",
  },

  {
    id: 2,
    title: "Premium",
    fee: "$5,000 - $9,999",
    feeBackground: "#CDFFB5",
    annualFee: "1.50%",
    advantage: "Reliable and timely support",
    textColor: "var(--green-1, #2C8900)",
    backgroundColor: "var(--green-5, #E4FFD7)",
  },

  {
    id: 3,
    title: "Diamond",
    fee: "$10,000 - $19,999",
    feeBackground: "#FFCDCD",
    annualFee: "1.00%",
    advantage: "Goal setting with an advisor",
    textColor: "var(--red-1, #BF0202);",
    backgroundColor: "var(--red-5, #FFE6E6)",
  },

  {
    id: 4,
    title: "Platinum",
    fee: "$20,000 & above",
    feeBackground: "#CEDCFF",
    annualFee: "0.85%",
    advantage: "Goal setting with an advisor",
    textColor: "#1B3E98",
    backgroundColor: "#E6EDFF",
  },
];
